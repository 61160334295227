
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import ShippingDetails from '@/freight/infrastructure/ui/quotation/form/shippingDetails/ShippingDetails.vue';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactionalUpdateStatus } from '@/tracking/application/uses_cases/activitiesTransactional/update/ActivityTransactionalUpdateStatus';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { PreRegisterSave } from '@/wms/application/preRegister/create/PreRegisterSave';
import { PreRegisterCreateReport } from '@/wms/application/preRegister/search/PreRegisterCreateReport';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { PreRegisterUpdate } from '@/wms/application/preRegister/update/PreRegisterUpdate';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import Vue from 'vue';
import Component from 'vue-class-component';
import BulkInformation from './components/BulkInformation.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
}

@Component({
  name: 'WarehouseLocateCenter',
  components: {
    ShippingDetails,
    BulkInformation
  }
})
export default class WarehouseLocateCenter extends Vue {
  @Inject(TYPES.PRE_REGISTER_SAVE)
  readonly preRegisterSave!: PreRegisterSave;
  @Inject(TYPES.PRE_REGISTER_UPDATE)
  readonly preRegisterUpdate!: PreRegisterUpdate;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  readonly activitiesTransactionalFindById!: FindTransactionalActivityById;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS)
  readonly updateStatusActivity!: ActivityTransactionalUpdateStatus;
  @Inject(TYPES.PRE_REGISTER_CREATE_REPORT)
  readonly createReport!: PreRegisterCreateReport;

  //Datos
  isLoading = false;
  quantity = 0;

  lists: Lists = {
    billOfLandingList: [],
    commodityClassesList: [],
    commodityList: [],
    containerList: [],
    customerList: [],
    destinationList: [],
    locationList: [],
    originList: [],
    packagingList: [],
    referencesList: [],
    typeOperationList: [],
    weightList: []
  };

  activityTransactional!: ActivityTransactional;

  //Formulario
  form: PreRegister = new PreRegister();

  //Getter para obtener los parametros de la URL
  get urlParams() {
    return {
      query: this.$route.query
    };
  }

  //Ciclo de vida del componente
  mounted() {
    //Si no viene desde una pantalla de busqueda entonces crea un pre registro vacio.
    'type' in this.urlParams.query && this.find();
    this.getActivityTransactional();
  }

  //Metodo ejecutado para guardar el objeto en la base de datos
  async update() {
    try {
      this.isLoading = true;
      this.form.state = this.form.state = PreRegisterStatusEnum.LOCATED;
      const res = await this.preRegisterUpdate.execute(this.form);
      if (!('error' in res)) {
        this.find();
        await this.executeActivity();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener un pre registro concreto
  async findPreRegister(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Metodo ejecutado para obtener los datos completos del documento basado en la primary key del mismo
  async find() {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: this.urlParams.query.type,
        number: this.urlParams.query.number
      });
      if (!('error' in res)) {
        this.form = res;
        this.form.state = PreRegisterStatusEnum.PRE_REGISTER;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  save() {
    try {
      if (!this.verifyLocationOnAllBulks()) {
        alert('Debe ubicar todos los bultos');
        return;
      }
      this.update();
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  verifyLocationOnAllBulks(): boolean {
    return this.form.bulks.every(bulk => {
      return bulk.isLocated;
    });
  }

  setLocation(location: ViewLocation, index: number) {
    // Map the location properties to the bulk properties
    if (!location) return;
    this.form.bulks[index] = Bulk.mapLocationToBulk(location, this.form.bulks[index]);
    this.form.bulks[index].isLocated = true;
  }

  /**
   * Funcion invocada para obtener la informacion de la actividad transaccional
   */
  async getActivityTransactional(): Promise<void> {
    try {
      if (!this.urlParams.query?.activityId || !this.urlParams.query?.eventId) return;
      this.isLoading = true;
      const res = await this.activitiesTransactionalFindById.execute({
        activityId: Number(this.urlParams.query?.activityId ?? '0'),
        eventId: Number(this.urlParams.query?.eventId ?? '0'),
        preRegisterNumber: Number(this.urlParams.query?.number ?? '0'),
        preRegisterType: String(this.urlParams.query?.type ?? '')
      });
      if (!('error' in res)) {
        this.activityTransactional = res;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  /**
   * Funcion invocada para actualizar el estado de la actividad transaccional
   */
  async executeActivity() {
    try {
      if (!this.activityTransactional || this.activityTransactional?.executed) return;
      this.isLoading = true;

      this.activityTransactional.executed = true;
      await this.updateStatusActivity.execute(this.activityTransactional);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async printReport(detail: string) {
    try {
      this.isLoading = true;
      const response = await this.createReport.internalExecute({
        number: Number(this.form.number),
        type: this.form.type,
        report: detail
      });

      // Tomamos el objeto URL generado por el servicio y descargamos el archivo que ya ha sido convertido en Blob
      const link = document.createElement('a');
      link.href = response;
      link.setAttribute('download', `location_report_${this.form.number}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Limpiamos la URL generada
      window.URL.revokeObjectURL(response);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
  sendBackToList() {
    this.$router.push('/dashboard/historyPanel');
  }
}
